import React, { useState, useRef } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { FaCommentDots } from 'react-icons/fa';
import './ChatBot.scss';

const theme = {
  background: '#f5f5f5',
  fontFamily: 'Arial, sans-serif',
  headerBgColor: '#0b5a4e',
  headerFontColor: '#fff',
  headerFontSize: '16px',
  botBubbleColor: '#0b5a4e',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4e',
};

const steps = [
  {
    id: '1',
    message: 'Welcome to UniDental! How can I assist you today?',
    trigger: 'options',
  },
  {
    id: 'options',
    options: [
      { value: 'appointment', label: 'Book an appointment', trigger: 'appointment' },
      { value: 'services', label: 'Services information', trigger: 'services' },
      { value: 'hours', label: 'Opening hours', trigger: 'hours' },
      { value: 'contact', label: 'Contact information', trigger: 'contact' },
    ],
  },
  {
    id: 'appointment',
    message: 'Great! To book an appointment, please call us at (123) 456-7890 or visit our website.',
    trigger: 'followUp',
  },
  {
    id: 'services',
    message: 'We offer a wide range of dental services including general dentistry, cosmetic dentistry, orthodontics, and more. Which service would you like to know more about?',
    trigger: 'followUp',
  },
  {
    id: 'hours',
    message: 'Our opening hours are:\nMonday-Friday: 9am-6pm\nSaturday: 10am-4pm\nSunday: Closed',
    trigger: 'followUp',
  },
  {
    id: 'contact',
    message: 'You can reach us at:\nPhone: (123) 456-7890\nEmail: info@unidental.com\nAddress: 123 Dental St, Tooth City, TC 12345',
    trigger: 'followUp',
  },
  {
    id: 'followUp',
    message: 'Is there anything else I can help you with?',
    trigger: 'followUpOptions',
  },
  {
    id: 'followUpOptions',
    options: [
      { value: 'yes', label: 'Yes', trigger: 'options' },
      { value: 'no', label: 'No, thank you', trigger: 'end' },
    ],
  },
  {
    id: 'end',
    message: 'Thank you for using UniDental chat. Have a great day!',
    end: true,
  },
];

const DentalChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const chatbotRef = useRef(null);

  const toggleBot = () => {
    if (chatbotRef.current && chatbotRef.current.toggleFloating) {
      chatbotRef.current.toggleFloating();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="dental-chatbot-container">
      <ThemeProvider theme={theme}>
        <ChatBot
          ref={chatbotRef}
          steps={steps}
          headerTitle="UniDental Bot"
          botAvatar="https://via.placeholder.com/32"
          userAvatar="https://via.placeholder.com/32"
          floating={true}
          floatingStyle={{
            background: '#0b5a4e',
            width: '60px',
            height: '60px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
          floatingIcon={<FaCommentDots size={24} color="#ffffff" />}
          hideUserAvatar={true}
          opened={isOpen}
          toggleFloating={toggleBot}
        />
      </ThemeProvider>
      <button className="chat-icon" onClick={toggleBot} style={{ display: isOpen ? 'none' : 'flex' }}>
        <FaCommentDots size={24} color="#ffffff" />
      </button>
    </div>
  );
};

export default DentalChatBot;