import { useState } from 'react';

const AppointmentFormManager = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('Kukatpally(HYD)');

  const openForm = (location = 'Kukatpally(HYD)') => {
    setSelectedLocation(location);
    setIsFormOpen(true);
  };
  
  const closeForm = () => setIsFormOpen(false);

  return { openForm, closeForm, isFormOpen, selectedLocation };
};

export default AppointmentFormManager;
