import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './AppointmentForm.scss';
import Captcha from '../Captcha/Captcha';

function AppointmentForm({ closeForm, selectedLocation }) {
  const [formData, setFormData] = useState({
    location: selectedLocation,  // Initialize with the selected location
    name: '',
    email: '',
    phone: '',
    date: '',
    message: '',
    agreement: true,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const validateField = (name, value) => {
    let error = '';
    
    // First check if field is empty
    if (!value || value.trim() === '') {
      switch (name) {
        case 'name':
          return 'Name is required';
        case 'email':
          return 'Email is required';
        case 'phone':
          return 'Phone number is required';
        default:
          return '';
      }
    }

    // Then check specific validations for each field
    switch (name) {
      case 'name':
        if (value.trim().length < 3) {
          error = 'Name must be at least 3 characters long';
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = 'Name should only contain letters and spaces';
        }
        break;

      case 'email':
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(value)) {
          error = 'Please enter a valid email address';
        }
        break;

      case 'phone':
        const phoneRegex = /^[6-9]\d{9}$/;
        if (!phoneRegex.test(value)) {
          error = 'Please enter a valid 10-digit Indian mobile number';
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    if (type !== 'checkbox') {
      const error = validateField(name, value);
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate all required fields
    Object.keys(formData).forEach(key => {
      if (key !== 'message' && key !== 'agreement' && key !== 'location') {
        const error = validateField(key, formData[key]);
        if (error) {
          newErrors[key] = error;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (validateForm() && isCaptchaValid && formData.agreement) {
      setIsSubmitting(true);
      try {
        await sendEmail();
      } catch (error) {
        console.error('Error sending email:', error);
        // You could add error notification here
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const sendEmail = async () => {
    try {
      const result = await emailjs.send(
        'service_lffniaa',
        'template_5yrtjei',
        {
          location: formData.location,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          date: formData.date,
          message: formData.message,
        }, 
        'YYf7gTHlbO0musIlg'
      );

      console.log(result.text);
      setIsSubmitted(true);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
        setIsVisible(false);
        setTimeout(closeForm, 300);
      }, 3000);

      setFormData({
        location: 'Kukatpally(HYD)',
        name: '',
        email: '',
        phone: '',
        date: '',
        message: '',
        agreement: false,
      });
      setIsCaptchaValid(false);
    } catch (error) {
      console.error('Email send error:', error);
      throw error;
    }
  };

  const handleCaptchaValidate = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  return (
    <div className={`appointment-form-overlay ${isVisible ? 'visible' : ''}`}>
      <div className={`appointment-form ${isVisible ? 'visible' : ''}`}>
        <button 
          className="close-btn" 
          onClick={() => {
            setIsVisible(false);
            setTimeout(closeForm, 300);
          }}
          aria-label="Close form"
        />
        <h2>Book Appointment</h2>
        {isSubmitted ? (
          <p className="success-message">Thank you for reaching out to us. We will respond to your inquiry as soon as possible.</p>
        ) : (
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group">
              <label>Location:</label>
              <div className="radio-group">
                {['Kukatpally(HYD)', 'Anantapur', 'Goa'].map((loc) => (
                  <label key={loc}>
                    <input
                      type="radio"
                      name="location"
                      value={loc}
                      checked={formData.location === loc}
                      onChange={handleChange}
                    />
                    {loc}
                  </label>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                placeholder="Enter your full name"
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                placeholder="Enter your email address"
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                id="phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                placeholder="Enter 10-digit mobile number"
                maxLength="10"
                className={errors.phone ? 'error' : ''}
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="date">Date:</label>
              <input
                id="date"
                type="text"
                name="date"
                value={formData.date}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                pattern="\d{2}/\d{2}/\d{4}"
                placeholder="dd/mm/yyyy"
                className={errors.date ? 'error' : ''}
              />
              {errors.date && <span className="error-message">{errors.date}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="message">Additional Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Any specific requirements or concerns"
              ></textarea>
            </div>

            <div className="form-group">
              <Captcha onValidate={handleCaptchaValidate} />
            </div>

            <div className="form-group checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="agreement"
                  checked={formData.agreement}
                  onChange={handleChange}
                  required
                />
                I agree to allow UNIDENTAL and its partners to contact me via phone, text message, and/or email.
              </label>
            </div>

            <button 
              type="submit" 
              className="submit-btn" 
              disabled={
                !formData.agreement || 
                !isCaptchaValid || 
                Object.keys(errors).some(key => errors[key]) ||
                isSubmitting
              }
            >
              {isSubmitting ? 'Submitting...' : 'Book Appointment'}
            </button>
          </form>
        )}
      </div>

      {showNotification && (
        <div className="notification">
          Appointment request form sent successfully!
        </div>
      )}
    </div>
  );
}

export default AppointmentForm;