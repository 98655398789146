import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {serviceData} from "./serviceData";
import AppointmentFormManager from "../AppointmentFormManager/AppointmentFormManager";
import AppointmentForm from "../AppointmentForm/AppointmentForm";
import "./ServiceDetail.scss";

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const service = serviceData[`/${serviceId}`];
  const { openForm, closeForm, isFormOpen, selectedLocation } = AppointmentFormManager();


  useEffect(() => {
    if (service) {
      document.title = service.documentTitle;
    }
    window.scrollTo(0, 0);
  }, [serviceId, service]);

  if (!service) {
    return (
      <div className="error-container">
        <div className="error-message">Service not found</div>
      </div>
    );
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "MedicalService",
    "name": service.title,
    "description": service.overview,
    "provider": {
      "@type": "Dentist",
      "name": "Unidental",
      "priceRange": "₹₹₹"
    },
    "medicalSpecialty": service.title,
    "availableService": service.procedures.map(proc => ({
      "@type": "MedicalProcedure",
      "name": proc.name,
      "description": proc.description
    }))
  };

  return (
    <>
      <Helmet>
        <title>{service.documentTitle}</title>
        <meta name="description" content={service.metaDescription} />
        <meta property="og:title" content={service.documentTitle} />
        <meta property="og:description" content={service.metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={service.documentTitle} />
        <meta name="twitter:description" content={service.metaDescription} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="service-container">
        <motion.div className="service-card">
          <motion.div className="service-header">
            <h1 className="service-title">{service.title}</h1>
            <button className="cta-button primary" onClick={() => openForm()}>Book Consultation</button>
          </motion.div>

          <div className="service-content">
            <motion.section className="service-section">
              <motion.p className="section-text overview-text">
                {service.overview}
              </motion.p>
            </motion.section>

            {service.whyChooseUs && (
              <motion.section className="service-section why-choose-section">
                <h2 className="section-title">Why Choose Unidental?</h2>
                <div className="why-choose-grid">
                  {service.whyChooseUs.map((item, index) => (
                    <motion.div key={index} className="why-choose-card">
                      <div className="why-choose-icon">
                        {index === 0 && <span className="icon">👨‍⚕️</span>}
                        {index === 1 && <span className="icon">🏥</span>}
                        {index === 2 && <span className="icon">✨</span>}
                        {index === 3 && <span className="icon">⏱️</span>}
                      </div>
                      <h3 className="why-choose-title">{item.title}</h3>
                      <p className="why-choose-description">
                        {item.description}
                      </p>
                    </motion.div>
                  ))}
                </div>
              </motion.section>
            )}

            <motion.section className="service-section">
              <h2 className="section-title">Our Services</h2>
              <div className="procedures-grid">
                {service.procedures.map((procedure, index) => (
                  <motion.div
                    key={index}
                    className="procedure-card"
                  >
                    <h3 className="procedure-title">{procedure.name}</h3>
                    <p className="procedure-description">{procedure.description}</p>
                  </motion.div>
                ))}
              </div>
            </motion.section>

            <motion.section className="service-section">
              <h2 className="section-title">Benefits</h2>
              <ul className="benefits-list">
                {service.benefits.map((benefit, index) => (
                  <motion.li key={index} className="benefit-item">
                    <span className="bullet">•</span>
                    {benefit}
                  </motion.li>
                ))}
              </ul>
            </motion.section>

            {service.testimonials && (
              <motion.section className="service-section">
                <h2 className="section-title">Patient Testimonials</h2>
                <div className="testimonials-grid">
                  {service.testimonials.map((testimonial, index) => (
                    <motion.div key={index} className="testimonial-card">
                      <p className="testimonial-text">{testimonial.text}</p>
                      <p className="testimonial-author">- {testimonial.author}</p>
                    </motion.div>
                  ))}
                </div>
              </motion.section>
            )}

            <motion.section className="service-section">
              <h2 className="section-title">Frequently Asked Questions</h2>
              <div className="faq-list">
                {service.faqs.map((faq, index) => (
                  <motion.div key={index} className="faq-item">
                    <h3 className="faq-question">{faq.question}</h3>
                    <p className="faq-answer">{faq.answer}</p>
                  </motion.div>
                ))}
              </div>
            </motion.section>

            {service.ctaSection && (
              <motion.section className="cta-section">
                <h2 className="cta-title">{service.ctaSection.title}</h2>
                <p className="cta-description">{service.ctaSection.description}</p>
                <button className="cta-button primary" onClick={() => openForm()}>
                  {service.ctaSection.buttonText}
                </button>
              </motion.section>
            )}
          </div>
        </motion.div>
        {isFormOpen && (
        <AppointmentForm 
          closeForm={closeForm} 
          selectedLocation={selectedLocation}
        />
      )}
      </div>
    </>
  );
};

export default ServiceDetail;