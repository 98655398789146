// HydLandingPage.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, MapPin, Phone, Clock, CheckCircle2, ArrowRight } from 'lucide-react';
import HydClinicImg from '../../assets/images/clinic.webp';
import { locationData } from './locationData';
import AppointmentForm from '../../components/AppointmentForm/AppointmentForm';
import AppointmentFormManager from '../../components/AppointmentFormManager/AppointmentFormManager';
import './HydLandingPage.scss';

// Button Component
const Button = ({ children, className, onClick, ...props }) => (
  <button className={`custom-button ${className}`} onClick={onClick} {...props}>
    {children}
  </button>
);

// Card Component
const Card = ({ children, className, ...props }) => (
  <div className={`custom-card ${className}`} {...props}>
    {children}
  </div>
);

// Icon mapping helper
const getIconComponent = (iconName) => {
  const icons = {
    Calendar,
    MapPin,
    Phone,
    Clock,
    CheckCircle2
  };
  return icons[iconName] || null;
};

// Location mapping
const locationMap = {
  'hyderabad': { key: 'hyderabad', display: 'Kukatpally(HYD)' },
  'anantapur': { key: 'anantapur', display: 'Anantapur' },
  'goa': { key: 'goa', display: 'Goa' }
};

const LandingPage = () => {
  const { location } = useParams();
  const locationKey = locationMap[location]?.key || 'hyderabad';
  const data = locationData[locationKey];
  
  const { openForm, closeForm, isFormOpen, selectedLocation } = AppointmentFormManager();

  const handleAppointmentClick = () => {
    const displayLocation = locationMap[location]?.display || 'Kukatpally(HYD)';
    openForm(displayLocation);
  };

  React.useEffect(() => {
    const capitalizedLocation = locationKey.charAt(0).toUpperCase() + locationKey.slice(1);
    document.title = `Unidental ${capitalizedLocation} | Dental Care You Can Trust`;
  }, [locationKey]);

  const getClinicImage = () => {
    try {
      return require(`../../assets/images/${locationKey}.webp`);
    } catch (e) {
      return HydClinicImg;
    }
  };

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero__overlay"></div>
        <div className="hero__container">
          <div className="hero__content">
            <h1 className="hero__title">
              {data.hero.title}
            </h1>
            <p className="hero__subtitle">
              {data.hero.subtitle}
            </p>
            <div className="hero__info">
              {data.hero.contactInfo.map((info, index) => {
                const IconComponent = getIconComponent(info.icon);
                return (
                  <div key={index} className="info-item">
                    <IconComponent className="info-icon" />
                    <span>{info.text}</span>
                  </div>
                );
              })}
            </div>
            <Button 
              className="hero__cta"
              onClick={handleAppointmentClick}
            >
              Schedule Appointment
            </Button>
          </div>
          <div className="hero__image-container">
            <div className="hero__image-gradient"></div>
            <img 
              src={getClinicImage()}
              alt={`Unidental ${locationKey.charAt(0).toUpperCase() + locationKey.slice(1)} Clinic`}
              className="hero__image"
            />
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services">
        <div className="services__pattern"></div>
        <div className="container">
          <h2 className="section-title">{data.services.title}</h2>
          <div className="services__grid">
            {data.services.items.map((service, index) => {
              const IconComponent = getIconComponent(service.icon);
              return (
                <Card key={index} className="services__card">
                  <div className="services__card-content">
                    {IconComponent && <IconComponent className="services__card-icon" />}
                    <h3 className="services__card-title">{service.title}</h3>
                    <p className="services__card-description">{service.description}</p>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="features">
        <div className="container">
          <h2 className="section-title">{data.features.title}</h2>
          <div className="features__grid">
            {data.features.items.map((feature, index) => {
              const IconComponent = getIconComponent(feature.icon);
              return (
                <div key={index} className="features__item">
                  <div className="features__icon-wrapper">
                    <IconComponent className="features__icon" />
                  </div>
                  <h3 className="features__title">{feature.title}</h3>
                  <p className="features__description">{feature.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <div className="container">
          <h2 className="section-title">{data.testimonials.title}</h2>
          <div className="testimonials__grid">
            {data.testimonials.items.map((testimonial, index) => (
              <Card key={index} className="testimonials__card">
                <div className="testimonials__content">
                  <p className="testimonials__text">"{testimonial.text}"</p>
                  <p className="testimonials__author">― {testimonial.author}</p>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <div className="container">
          <h2 className="section-title">{data.faq.title}</h2>
          <div className="faq__grid">
            {data.faq.items.map((faq, index) => (
              <Card key={index} className="faq__card">
                <div className="faq__content">
                  <h3 className="faq__question">{faq.question}</h3>
                  <p className="faq__answer">{faq.answer}</p>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta">
        <div className="cta__container">
          <div className="cta__content">
            <Calendar className="cta__icon" />
            <h2 className="cta__title">{data.cta.title}</h2>
            <p className="cta__text">{data.cta.text}</p>
            <div 
              className="cta__button"
              onClick={handleAppointmentClick}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAppointmentClick();
                }
              }}
            >
              <span>{data.cta.buttonText}</span>
              <ArrowRight className="cta__button-icon" />
            </div>
          </div>
          <div className="cta__decoration">
            <div className="cta__circle cta__circle--1"></div>
            <div className="cta__circle cta__circle--2"></div>
            <div className="cta__circle cta__circle--3"></div>
          </div>
        </div>
      </section>

      {/* Appointment Form Modal */}
      {isFormOpen && (
        <AppointmentForm 
          closeForm={closeForm} 
          selectedLocation={selectedLocation}
        />
      )}
    </div>
  );
};

export default LandingPage;