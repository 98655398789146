import React, { useState, useEffect } from 'react';
import './PromoBanner.scss';

const offers = [
  {
    title: "Free Examination for New Patients",
    description: "New patients receive a comprehensive dental examination at no cost."
  },
  {
    title: "2nd Opinion is Absolutely Free",
    description: "Get a second opinion on any dental diagnosis or treatment plan, free of charge."
  },
];

function PromoBanner() {
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentOfferIndex((prevIndex) => (prevIndex + 1) % offers.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentOfferIndex(index);
  };

  return (
    <div className="promo-banner">
      <div className="promo-content">
        <h2 className="animated-text">{offers[currentOfferIndex].title}</h2>
        <p className="animated-text">{offers[currentOfferIndex].description}</p>
      </div>
      <div className="offer-indicators">
        {offers.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentOfferIndex ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default PromoBanner;