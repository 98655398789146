import React from 'react';
import './Reviews.scss';

const ReviewCards = () => {
  const reviews = [
    { id: 1, name: "NAGARJUNA", text: "I had a great Experience at UNIDENTAL CLINIC .the case was gentle & Considerate, which made me feel comfortable. Through the treatment and they explain me about the treatment at each & every step in the root canal treatment", rating: 3},
    { id: 2, name: "NAVEEN", text: "The Orthodontist provide me excellent treatment & I am fully Satisfied with the outcome & their Care towards the patient I appreciable & staff delivers great customer’s service.", rating: 4 },
    { id: 3, name: "SWETHA", text: "Thank you for the doctors for providing utmost care and explaining in detail about various treatment modalities and I was happy to get my treatment done in such a clean environment.", rating: 5 },
    { id: 4, name: "ARJUN", text: "Friendly atmosphere with comfortable & Painless Treatment by the doctor. I am satisfied with the treatment very much & I advice to visit the clinic heartfully.", rating: 4 },
    { id: 5, name: "KUMARASWAMY", text: "I had an unbearable pain in my tooth and my friend advice referred me to this clinic and I was very happy with the treatment & condition provided by the doctors, explanation of the problem & procedure was very good.", rating: 5 },
    { id: 6, name: "SADHASIVA", text: "I greatly appreciate your care , everything you have done for me . ", rating: 5 },
    { id: 7, name: "MANOHAR", text: "I am very happy & satisfied with the doctors & this staff , was extremely nervous getting my 4 impacted wisdom tooth out & they put me at ease .he doctor takes his time &really cares about his patients.", rating: 5 },
    { id: 8, name: "MALLIKARJUNA", text: "Great! Quick recovery from facial bone fractures, I did not have any bad pain or swelling, everything went very Smoothly.Great response & care taken by the doctors.", rating: 5 },
    { id: 9, name: "GOWTHAMI", text: "Great experience with the Orthodontist ,No painful procedure, explained clearly about the procedure &taken utmost care throughout the treatment .very much Satisfied with the treatment & very comfortable with the doctors.", rating: 5 },
    { id: 10, name: "MADHU", text: "Professional & expert in everyway ,caring ,answers questions and great with follow up. office is well organized & everyone is friendly &knows what they are doing. Highly  recommended.", rating: 5 },
  ];

  const renderStars = (rating) => {
    const fullStars = Array(rating).fill('★');
    const emptyStars = Array(5 - rating).fill('☆');
    return [...fullStars, ...emptyStars].map((star, index) => (
      <span key={index} className="star">
        {star}
      </span>
    ));
  };

  return (
    <div className="review-cards-container">
      <div className="review-cards-scroller">
        {reviews.concat(reviews).map((review, index) => (
          <div className="review-card" key={index}>
            {/* <div className="review-stars">{renderStars(review.rating)}</div> */}
            <p className="review-text">"{review.text}"</p>
            <p className="review-author">- {review.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCards;
