// serviceData.js
export const serviceData = {
  '/general-dentistry': {
    title: 'General Dentistry',
    documentTitle: 'Best General Dentistry in India | Unidental | Deep Teeth Cleaning',
    overview: 'General dentistry forms the foundation of good oral health. Our comprehensive general dentistry services focus on prevention, diagnosis, and treatment of various dental conditions to maintain your oral health. We provide services including deep teeth cleaning, dentistry for root canals, and enamel restoration.',
    procedures: [
      {
        name: 'Dental Cleanings',
        description: 'Professional cleaning to remove plaque and tartar, keeping your teeth and gums healthy. Our hygienists use advanced ultrasonic technology and gentle techniques for thorough cleaning and comfort.',
        additionalInfo: 'Additonal Info'
      },
      {
        name: 'Exams and X-rays',
        description: 'Thorough examination and diagnostic imaging to detect oral health issues early, including comprehensive oral cancer screening and periodontal evaluation.',
      },
      {
        name: 'Cavity Fillings',
        description: 'Treatment of tooth decay using modern, tooth-colored composite materials for a natural look and restored function.',
      },
      {
        name: 'Fluoride Treatments',
        description: 'Professional fluoride treatments to strengthen enamel and protect against decay, including deep remineralization for early signs of decay.',
      },
      {
        name: 'Sealants',
        description: 'Protective coating applied to prevent decay in teeth\'s grooves, providing lasting protection for vulnerable areas.',
      }
    ],
    benefits: [
      'Prevention of tooth decay and gum disease',
      'Early detection of dental problems',
      'Maintenance of overall oral health',
      'Protection against future dental issues',
      'Cost-effective preventive care',
    ],
    faqs: [
      {
        question: 'How often should I have a dental check-up?',
        answer: 'We recommend visiting every 6 months for routine check-ups and professional cleaning to maintain oral health.',
      },
      {
        question: 'Are fluoride treatments safe for children?',
        answer: 'Yes, fluoride treatments are not only safe but beneficial for children, as they help to strengthen developing enamel and reduce the risk of cavities.',
      },
      {
        question: 'What can I expect during a general dentistry visit?',
        answer: 'During your visit, our dental team will thoroughly clean your teeth, conduct a comprehensive examination, and take X-rays if necessary. We’ll discuss any findings with you and recommend treatments or preventive measures as needed.',
      }
    ],
    whyChooseUs: [
      {
        title: "Experienced Dentists",
        description: "Our team of orthodontists is skilled in providing comprehensive care for all age groups."
      },
      {
        title: "Modern Environment",
        description: "We use advanced technology to design customized treatment plans for quicker and more effective results",
      },
      {
        title: "Comfortable, Personalized Care",
        description: "We prioritize patient comfort and make sure that each treatment plan is tailored to your unique needs."
      },
      {
        title: "Long-Term Results",
        description: "Our orthodontic solutions are designed to provide lasting results, improving both the function and aesthetics of your smile."
      }, 
    ],
    ctaSection: {
      title: "Ready to Transform Your Smile?",
      description: "Contact us to schedule your consultation, and let our skilled dentists create a personalized plan just for you.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'General dentistry treatment at Unidental focuses on diagnosing, treating, and preventing oral diseases with services like deep teeth cleaning and root canal dentistry.',
  },

  '/cosmetic-dental-services': {
    title: 'Cosmetic Dentistry',
    documentTitle: 'Transform Your Smile with Professional Cosmetic Dentistry | Unidental',
    hero: {
      headline: 'Transform Your Smile with Professional Cosmetic Dentistry',
      subheadline: 'Enhance Your Smile with Customized Cosmetic Dental Treatments for a Confident, Natural Look',
      ctaText: 'Book Your Cosmetic Dentistry Consultation'
    },
    overview: 'At Unidental, we believe everyone deserves a beautiful smile. Our cosmetic dentistry services focus on enhancing the appearance of your teeth and boosting your confidence. Whether you\'re looking to whiten your teeth, conceal imperfections, or achieve a complete smile makeover, our expert team is here to provide safe, effective treatments that yield natural-looking results.',
    procedures: [
      {
        name: 'Teeth Whitening',
        description: 'Our professional teeth whitening treatments effectively remove stains and brighten teeth. We offer both in-office whitening for fast, visible results and customized take-home kits for convenient, gradual whitening at your own pace.',
      },
      {
        name: 'Veneers',
        description: 'Dental veneers are thin, custom-made shells crafted to improve the appearance of chipped, stained, or misaligned front teeth. Veneers provide a long-lasting solution with a natural look, ideal for creating a flawless smile.',
      },
      {
        name: 'Dental Crowns (Teeth Capping)',
        description: 'Crowns, or teeth caps, are durable covers placed over damaged or discolored teeth. They not only improve appearance but also protect the tooth from further damage, ensuring lasting oral health.',
      },
      {
        name: 'Bonding and Contouring',
        description: 'Perfect for minor imperfections, bonding and contouring address chips, gaps, and uneven edges for a balanced and polished look.',
      }
    ],
    benefits: [
      'Enhanced smile aesthetics',
      'Boosted confidence in social and professional settings',
      'Natural-looking results',
      'Minimally invasive options available',
    ],
    faqs: [
      {
        question: 'How long do results from teeth whitening last?',
        answer: 'Results vary based on lifestyle factors such as diet and oral hygiene. Some patients may need touch-ups every 6-12 months for optimal brightness.',
      },
      {
        question: 'Are veneers painful?',
        answer: 'The veneer process is minimally invasive, and we prioritize your comfort throughout. Some patients may experience mild sensitivity, but it\'s typically short-lived.',
      },
      {
        question: 'How do I care for my teeth after cosmetic procedures?',
        answer: 'Good oral hygiene and regular dental check-ups help maintain results. Avoiding stain-causing foods and beverages can also extend the longevity of your treatments.',
      }
    ],
    testimonials: [
      {
        text: "Unidental helped me achieve my dream smile with veneers. I feel more confident and happy with my appearance.",
        author: "Ananya R."
      },
      {
        text: "The whitening treatment was quick, and my teeth look amazing. I highly recommend Unidental!",
        author: "Rahul M."
      }
    ],
    whyChooseUs: [
      {
        title: "Experienced Dentists",
        description: "Our team of orthodontists is skilled in providing comprehensive care for all age groups."
      },
      {
        title: "Modern Environment",
        description: "We use advanced technology to design customized treatment plans for quicker and more effective results",
      },
      {
        title: "Comfortable, Personalized Care",
        description: "We prioritize patient comfort and make sure that each treatment plan is tailored to your unique needs."
      },
      {
        title: "Long-Term Results",
        description: "Our cosmetic solutions are designed to provide lasting results, improving both the function and aesthetics of your smile."
      }, 
    ],
    ctaSection: {
      title: "Ready to Transform Your Smile?",
      description: "Contact us to schedule your consultation, and let our skilled cosmetic dentists create a personalized plan just for you.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Transform your smile with professional cosmetic dentistry at Unidental. We offer teeth whitening, veneers, crowns, and bonding services for a confident, natural-looking smile.',
  },

  '/best-invisalign-braces': {
    title: 'Orthodontics',
    documentTitle: 'Best Invisalign Braces at Unidental | Invisible Braces',
    overview: 'Our orthodontic services cover braces, Invisalign, and orthodontic treatments for all ages, helping you achieve the perfect smile with maximum comfort.',
    procedures: [
      {
        name: 'Traditional Metal Braces',
        description: 'Effective teeth alignment with modern metal braces for complex orthodontic issues.',
      },
      {
        name: 'Clear Aligners',
        description: 'Discreet teeth straightening with custom-made, removable aligners.',
      },
      {
        name: 'Orthodontic Treatment for All Ages',
        description: 'We provide orthodontic solutions for children, teenagers, and adults, ensuring that everyone can benefit from our expert care, regardless of their age.',
      }
    ],
    whyChooseUs: [
      {
        title: "Experienced Orthodontic Specialist",
        description: "Our team of orthodontists is skilled in providing comprehensive care for all age groups."
      },
      {
        title: "State-of-the-Art Technology",
        description: "We use advanced technology to design customized treatment plans for quicker and more effective results",
      },
      {
        title: "Comfortable, Personalized Care",
        description: "We prioritize patient comfort and make sure that each treatment plan is tailored to your unique needs."
      },
      {
        title: "Long-Term Results",
        description: "Our orthodontic solutions are designed to provide lasting results, improving both the function and aesthetics of your smile."
      }, 
    ],
    testimonials: [
      {
        text: "I’m so happy with the results of my Invisalign treatment. It was so easy to wear the aligners, and the best part is they’re completely clear!",
        author: "Priya S."
      },
      {
        text: "Traditional braces worked wonders for my son. He now has a perfectly straight smile, and the process was so smooth thanks to the amazing team at Unidental.",
        author: "Sandeep R."
      }
    ],
    benefits: [
      'Improved smile aesthetics',
      'Better bite alignment',
      'Easier cleaning and maintenance',
      'Enhanced oral health',
    ],
    faqs: [
      {
        question: 'How long does orthodontic treatment take?',
        answer: 'Treatment duration varies, typically between 12 and 36 months depending on case complexity.',
      },
      {
        question: 'Can adults benefit from orthodontic treatment?',
        answer: 'Yes, orthodontic treatment is not limited to children and teenagers. Many adults opt for braces or Invisalign to improve their smile and oral health.',
      },
      {
        question: 'Are clear aligners as effective as traditional braces?',
        answer: 'Clear aligners are highly effective for many cases, but for more complex issues, traditional braces may be the better option. Your orthodontist will help determine the most suitable treatment for your needs.',
      }
    ],
    ctaSection: {
      title: "Ready to straighten your teeth and improve your smile?",
      description: "Contact us today to schedule your consultation and take the first step toward a more confident, healthier smile.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers orthodontic services including braces and Invisalign, helping you achieve an aligned smile with comfort and ease.',
  },

  '/best-root-canal-treatments-in-india': {
    title: 'Endodontics',
    documentTitle: 'Root Canal Treatment at Unidental | Cracked Tooth Treatments',
    overview: 'Endodontic treatment focuses on saving natural teeth through procedures like root canal therapy and cracked tooth treatments. Using advanced technology, we provide precise, comfortable care for lasting results.',
    procedures: [
      {
        name: 'Root Canal Therapy',
        description: 'Treatment to remove infected pulp and save the natural tooth. Using microscopic visualization and modern techniques, we thoroughly clean and seal the root canal system for effective treatment.',
      },
      {
        name: 'Retreatment',
        description: 'Revision of previous root canal treatment when necessary, addressing new or persistent infection to save your natural tooth.',
      },
      {
        name: 'Apicoectomy',
        description: 'Surgical procedure to treat infection at the root tip, removing infected tissue and sealing the root end.',
      },
      {
        name: 'Cracked Tooth Treatment',
        description: 'Specialized diagnosis and treatment to prevent further damage and preserve natural tooth structure.',
      },
      {
        name: 'Emergency Care',
        description: 'Immediate treatment for severe tooth pain and dental emergencies requiring root canal therapy.'
      }
    ],
    whyChooseUs: [
      {
        title: "Expert Endodontic Specialists",
        description: "Our team consists of highly trained endodontists who specialize in saving teeth through advanced procedures"
      },
      {
        title: "Cutting-Edge Technology",
        description: "We utilize the latest in endodontic technology, including microscopic visualization, to ensure precise and effective treatments.",
      },
      {
        title: "Pain-Free Procedures",
        description: "With modern techniques and anesthesia, we ensure that your treatment is as comfortable as possible."
      },
      {
        title: "Long-Term Results",
        description: "We prioritize the long-term health and survival of your natural teeth, offering treatments that can last a lifetime with proper care."
      }, 
    ],
    benefits: [
      'Relief from tooth pain',
      'Preservation of natural teeth',
      'Prevention of tooth loss',
      'Restoration of normal bite function',
      'Long-term tooth survival',
      'Prevention of bone loss',
      'Maintenance of natural smile',
      'Cost-effective versus extraction',
      'Minimal recovery time',
      'High success rate'
    ],
    faqs: [
      {
        question: 'Is root canal treatment painful?',
        answer: 'With modern techniques and anesthesia, root canal treatment is comfortable, providing significant pain relief afterward.',
      },
      {
        question: 'How long does a root canal last?',
        answer: 'With proper care, teeth treated with root canal therapy can last a lifetime. Regular dental check-ups are essential for success.',
      }
    ],
    testimonials: [
      {
        text: "I was nervous about my root canal, but the team at Unidental made me feel comfortable. The procedure was quick and painless, and I haven’t had any pain since",
        author: "Ravi M."
      },
      {
        text: "I had an apicoectomy and was worried about recovery time, but it was minimal. I’m glad I saved my tooth instead of extracting it.",
        author: "Geeta P."
      }
    ],
    ctaSection: {
      title: "Don’t let tooth pain or infection affect your daily life",
      description: "Contact us today to schedule your consultation and receive expert care from our experienced team.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers expert root canal therapy and cracked tooth treatments to preserve your natural smile and relieve pain.'
  },

  '/gum-disease-treatments': {
    title: 'Periodontics',
    documentTitle: 'Best Gum Disease Treatments at Unidental | The Best Dental Hospital in India',
    overview: 'Our periodontal services treat gum disease and maintain gum health using advanced techniques and technology for effective and lasting results.',
    procedures: [
      {
        name: 'Scaling and Root Planing',
        description: 'Deep cleaning to remove tartar and smooth root surfaces, promoting healing of gum tissues.',
      },
      {
        name: 'Gum Grafting',
        description: 'Surgical procedure to cover exposed roots and treat gum recession, restoring gum tissue and protecting vulnerable root surfaces.',
      }
    ],
    benefits: [
      'Healthier gums',
      'Prevention of tooth loss',
      'Reduced inflammation',
      'Better oral health',
      'Fresh breath',
      'Decreased risk of systemic health issues',
      'Improved smile aesthetics',
      'Long-term gum stability',
      'Enhanced comfort',
      'Better overall health'
    ],
    whyChooseUs: [
      {
        title: "Experienced Periodontists",
        description: "Our team consists of skilled periodontists who specialize in gum health and treating periodontal disease."
      },
      {
        title: "Advanced Technology",
        description: "We utilize the latest periodontal techniques and technologies to ensure precise, effective treatments.",
      },
      {
        title: "Minimally Invasive Procedures",
        description: "Our procedures are designed to be minimally invasive, promoting quicker recovery and optimal results."
      },
      {
        title: "Comprehensive Care",
        description: "We offer a range of treatments to address all stages of gum disease, from prevention to advanced surgical procedures."
      }, 
    ],
    faqs: [
      {
        question: 'What are the signs of gum disease?',
        answer: 'Common signs include bleeding gums, bad breath, gum recession, and loose teeth. Early detection is crucial for successful treatment.',
      },
      {
        question: 'Is periodontal disease curable?',
        answer: 'While early stages can be reversed, advanced periodontal disease requires ongoing management to control.',
      }
    ],
    testimonials: [
      {
        text: "I had gum recession and was concerned about losing my teeth. The gum grafting procedure was quick and the recovery was easier than I expected. My smile looks great again!",
        author: "Neha K."
      },
      {
        text: "Scaling and root planing helped me avoid further gum problems. My gums feel healthier, and I no longer have to worry about bad breath!",
        author: "Sanjay R."
      }
    ],
    ctaSection: {
      title: "Don’t wait for gum problems to escalate",
      description: "Our periodontists are ready to help you achieve optimal gum health and a beautiful, healthy smile",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers comprehensive gum disease treatments like scaling, root planing, and gum grafting to support gum health and prevent tooth loss.'
  },

  '/oral-maxillofacial-surgery': {
    title: 'Oral Surgery',
    documentTitle: 'Oral and Maxillofacial Surgery in India | Unidental | Best Dental Hospital',
    overview: 'Our oral surgery services provide solutions for complex dental needs, from tooth extraction to corrective jaw surgery, helping restore oral health and function.',
    procedures: [
      {
        name: 'Wisdom Tooth Extraction',
        description: 'Removal of impacted or problematic wisdom teeth using advanced surgical techniques for precise extraction and minimal recovery time.',
      },
      {
        name: 'Dental Implants',
        description: 'Surgical placement of titanium implants to create stable foundations for replacement teeth, restoring both function and aesthetics.',
      }
    ],
    benefits: [
      'Relief from pain and discomfort',
      'Improved facial structure and alignment',
      'Enhanced stability for dental implants',
      'Better bite function',
      'Improved speech capability',
      'Long-term oral health solutions',
      'Prevention of future complications',
      'Aesthetic improvements'
    ],
    whyChooseUs: [
      {
        title: "Experienced Oral Surgeons",
        description: "Our team of skilled oral surgeons has years of experience in performing a wide range of surgical procedures."
      },
      {
        title: "Advanced Technology",
        description: "We utilize the latest periodontal techniques and technologies to ensure precise, effective treatments.",
      },
      {
        title: "Comprehensive Care",
        description: "Our procedures are designed to be minimally invasive, promoting quicker recovery and optimal results."
      },
      {
        title: "Patient Comfort",
        description: "Your comfort is our priority. We use advanced anesthesia options and pain management techniques to ensure a pain-free experience during and after surgery.."
      }, 
    ],
    faqs: [
      {
        question: 'Is oral surgery painful?',
        answer: 'Procedures are performed under appropriate anesthesia, and pain can be managed effectively with medication afterward.',
      },
      {
        question: 'How long does recovery take?',
        answer: 'Recovery time varies depending on the procedure, typically ranging from a few days to a few weeks.',
      }
    ],
    testimonials: [
      {
        text: "The wisdom tooth extraction was much easier than I expected. I was back to my normal routine in just a few days!",
        author: "Priya S."
      },
      {
        text: "I’ve had a missing tooth for years, and the dental implant procedure gave me a natural-looking replacement. It feels great to smile again with confidence!",
        author: "Ravi K."
      }
    ],
    ctaSection: {
      title: "Ready to restore your oral health and smile?",
      description: "Schedule a consultation with our expert oral surgeons to discuss your treatment options and learn how oral surgery can benefit you.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers oral surgery services like wisdom tooth extraction and dental implants for long-term oral health solutions.'
  },

  '/false-teeth-dentures': {
    title: 'Prosthodontics',
    documentTitle: 'Best False Teeth Dentures at Unidental | Restore Your Smile with Confidence',
    overview: 'Prosthodontic services restore damaged or missing teeth using artificial replacements to improve oral function and aesthetics. Our specialists create beautiful, functional restorations that look and feel natural. We utilize advanced materials and techniques to ensure long-lasting results.',
    procedures: [
      {
        name: 'Dentures',
        description: 'Custom-made removable replacements for missing teeth, designed for optimal fit and appearance. Options include full, partial, and implant-supported dentures.',
      },
      {
        name: 'Dental Crowns',
        description: 'Covers damaged teeth, restoring function and appearance with high-quality materials that match your natural teeth.',
      },
      {
        name: 'Bridges',
        description: 'Fixed prosthetic replacing one or more missing teeth, crafted to restore your smile and bite function.',
      },
      {
        name: 'Implant-Supported Prosthesis',
        description: 'Advanced prosthetic anchored by dental implants for maximum stability and natural function.',
      },
      {
        name: 'Full Mouth Reconstruction',
        description: 'Comprehensive restoration of all teeth to rebuild both function and aesthetics for optimal oral health.',
      }
    ],
    benefits: [
      'Restored oral function',
      'Enhanced appearance',
      'Improved self-confidence',
      'Durable, long-lasting solutions',
      'Better chewing ability',
      'Clearer speech',
      'Preserved facial structure',
      'Comfortable fit',
      'Natural-looking results',
      'Improved quality of life'
    ],
    whyChooseUs: [
      {
        title: "Expert Prosthodontists",
        description: "Our team is highly skilled in creating lifelike dental restorations that blend seamlessly with your natural teeth.."
      },
      {
        title: "Personalized Care",
        description: "We understand that every patient’s needs are unique, so we provide personalized treatment plans for the best results",
      },
      {
        title: "Advanced Materials",
        description: "We use high-quality, durable materials that mimic the look and feel of natural teeth, ensuring long-lasting, beautiful results."
      },
      {
        title: "Comprehensive Solutions",
        description: "From single-tooth restorations to full mouth reconstruction, we offer a full range of prosthodontic services for all dental needs."
      }, 
    ],
    faqs: [
      {
        question: 'How long do dentures last?',
        answer: 'With proper care, dentures can last 5-10 years before needing replacement. Regular adjustments help maintain proper fit.',
      },
      {
        question: 'Are dental crowns noticeable?',
        answer: 'Our crowns are crafted to match your natural teeth for a seamless look using high-quality materials.',
      }
    ],
    testimonials: [
      {
        text: "The dentures I received from Unidental have changed my life. I can eat, speak, and smile without feeling self-conscious. Thank you for restoring my confidence!",
        author: "Priya S."
      },
      {
        text: "I was hesitant about getting a crown, but the team made the process so easy. My crown looks and feels just like my natural tooth. I’m thrilled with the results!",
        author: "Ravi K."
      }
    ],
    ctaSection: {
      title: "Ready to restore your smile?",
      description: "We can help you achieve a more functional and aesthetically pleasing smile.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers advanced denture services including full and implant-supported dentures, dental crowns, and bridges to restore your smile.'
  },

'/best-treatment-for-oral-diseases': {
    title: 'Oral Pathology',
    documentTitle: 'Diagnosis and Treatment for Oral Diseases | Oral Pathology at Unidental',
    overview: 'Our oral pathology services focus on diagnosing and managing diseases affecting the oral regions. Using advanced diagnostic techniques, we identify and treat a range of conditions for successful outcomes.',
    procedures: [
      {
        name: 'Biopsy',
        description: 'Sampling of suspicious oral lesions for diagnostic evaluation, including incisional and brush biopsies.',
      },
      {
        name: 'Oral Cancer Screening',
        description: 'Routine screening using advanced visualization technology to detect oral cancers early for better outcomes.',
      },
      {
        name: 'Lesion Removal',
        description: 'Surgical removal of concerning growths or lesions with precise techniques and minimal tissue disruption.',
      }
    ],
    benefits: [
      'Early detection of oral diseases',
      'Timely treatment planning',
      'Comprehensive oral health monitoring',
      'Prevention of disease progression',
      'Advanced diagnostic capabilities',
    ],
    whyChooseUs: [
      {
        title: "Advanced Diagnostic Technology",
        description: "We use state-of-the-art diagnostic tools to accurately assess and diagnose oral conditions."
      },
      {
        title: "Expert Pathologists",
        description: "Our team includes experienced oral pathologists dedicated to providing you with thorough evaluations and effective treatment options.",
      },
      {
        title: "Timely Treatment",
        description: "We offer quick results for biopsies and screenings to ensure that any issues are addressed promptly."
      },
      {
        title: "Comprehensive Care",
        description: "From routine screenings to surgical removal of lesions, we provide a complete range of oral pathology services."
      }, 
    ],
    faqs: [
      {
        question: 'What conditions does oral pathology diagnose?',
        answer: 'Oral pathology diagnoses conditions like oral cancer, infections, and mucosal diseases affecting the oral cavity.',
      },
      {
        question: 'What are common signs of oral pathology issues?',
        answer: 'Unusual lumps, persistent sores, white or red patches, and changes in oral tissue texture may indicate pathology issues.',
      }
    ],
    ctaSection: {
      title: "Don’t wait for oral health issues to worsen",
      description: "Schedule a consultation with Unidental's oral pathology specialists today.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers expert diagnosis and treatment of oral diseases through comprehensive oral pathology services for optimal health outcomes.'
  },

'/best-treatment-for-mouth-ulcers': {
    title: 'Oral Medicine',
    documentTitle: 'Mouth Ulcers Treatment | Comprehensive Care for Your Mouth',
    overview: 'Oral medicine addresses complex oral issues often linked to systemic conditions. Our specialists provide comprehensive care for patients, working closely with other medical professionals to ensure coordinated treatment.',
    procedures: [
      {
        name: 'Management of Oral Lesions',
        description: 'Treatment and monitoring of oral sores and ulcers using advanced therapeutic approaches.',
      },
      {
        name: 'Treatment of Oral Infections',
        description: 'Diagnosis and treatment of oral fungal, viral, and bacterial infections with targeted therapies.',
      },
      {
        name: 'Chronic Pain Management',
        description: 'Comprehensive treatment of chronic oral pain, including burning mouth syndrome and neuralgias.',
      }
    ],
    benefits: [
      'Relief from oral pain',
      'Improved management of systemic conditions',
      'Expert medical consultation',
      'Coordinated care approach',
      'Better quality of life',
    ],
    whyChooseUs: [
      {
        title: "Comprehensive Care Approach",
        description: "We take a multidisciplinary approach by collaborating with other medical professionals, ensuring the best possible treatment for your oral and systemic health."
      },
      {
        title: "Expert Diagnosis and Treatment",
        description: "Our oral medicine specialists are trained to handle complex oral conditions, providing accurate diagnoses and tailored treatment plans.",
      },
      {
        title: "Relief from Chronic Pain",
        description: "Whether you're suffering from burning mouth syndrome or other chronic pain conditions, we provide targeted therapies to manage discomfort."
      },
      {
        title: "Holistic Care",
        description: "We focus not just on oral health, but on how it relates to your overall well-being, offering integrated solutions for systemic conditions."
      }, 
    ],
    faqs: [
      {
        question: 'What is the role of oral medicine?',
        answer: 'Oral medicine addresses oral health issues related to conditions like autoimmune diseases and infections.',
      },
      {
        question: 'Do oral infections affect general health?',
        answer: 'Yes, untreated oral infections can impact overall health and may complicate medical conditions.',
      }
    ],
    ctaSection: {
      title: "Don’t let complex oral conditions affect your quality of life",
      description: "Get in touch today to schedule your consultation.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental provides specialized oral medicine services for complex oral conditions and infections linked with other health issues.'
  },
  '/dental-implants': {
    title: 'Dental Implants',
    documentTitle: 'Best Dental Implant Clinic | Full Mouth Dental Implants',
    overview: 'Dental implants offer a permanent, natural-looking solution to replace missing teeth, restoring both function and aesthetics. Our advanced implant techniques ensure precise placement and integration, providing long-lasting results that look and feel like real teeth.',
    procedures: [
      {
        name: 'Single Tooth Implant',
        description: 'Replacement of a single missing tooth with a titanium implant and custom-crafted crown, perfectly matched to your natural teeth.',
      },
      {
        name: 'Implant-Supported Bridge',
        description: 'Solution for multiple missing teeth using strategically placed implants to anchor a permanent bridge, restoring full function and appearance.',
      },
      {
        name: 'Full Arch Restoration',
        description: 'Complete replacement of all teeth in the upper or lower jaw with advanced techniques for maximum stability and comfort.',
      },
      {
        name: 'Mini Implants',
        description: 'Smaller diameter implants ideal for patients with limited bone volume or specific placement needs.',
      },
      {
        name: 'Immediate Load Implants',
        description: 'Same-day tooth replacement for qualifying patients, minimizing time spent without teeth and maximizing convenience.',
      }
    ],
    benefits: [
      'Permanent tooth replacement',
      'Enhanced chewing and speaking',
      'Natural look and feel',
      'Durable and long-lasting',
      'Preserves jawbone health',
      'Improved facial aesthetics',
      'No impact on adjacent teeth',
      'Boosted self-confidence',
      'Improved oral health',
      'Lifetime solution'
    ],
    whyChooseUs: [
      {
        title: "Advanced Implant Technology",
        description: "We use the latest techniques and technology for precise and minimally invasive dental implant placement."
      },
      {
        title: "Experienced Implant Specialists",
        description: "Our dental implant specialists have extensive training and experience, ensuring the best possible outcomes.",
      },
      {
        title: "Customized Solutions",
        description: "Each implant is tailored to match your natural teeth, ensuring a seamless fit and a natural look."
      },
      {
        title: "Long-Term Success",
        description: "Dental implants are designed to last a lifetime with proper care, providing you with lasting oral health and confidence."
      }, 
    ],
    faqs: [
      {
        question: 'Are dental implants painful?',
        answer: 'The procedure is performed with local anesthesia, and most patients report minimal discomfort afterward. We offer pain management options for your comfort.',
      },
      {
        question: 'How long do dental implants last?',
        answer: 'With proper care, dental implants can last a lifetime. The crown may need replacement after 10-15 years due to wear.',
      }
    ],
    ctaSection: {
      title: "Ready to restore your smile and improve your oral health with dental implants?",
      description: "We’ll help you explore the best dental implant solutions to meet your unique needs.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers advanced dental implants for single, multiple, or full arch replacements, restoring smiles with natural-looking, durable solutions.'
  },

'/dental-laser-treatment': {
    title: 'Laser Dentistry',
    documentTitle: 'Best Dental Laser Treatment at Unidental | Best Dental Clinic',
    overview: 'Laser dentistry offers minimally invasive treatment options that enhance comfort and speed recovery. Our advanced laser technology allows for precise, gentle care with less anesthesia and faster healing times.',
    procedures: [
      {
        name: 'Gum Contouring',
        description: 'Laser reshaping of the gums for improved aesthetics or health, with precise tissue management and minimal bleeding.',
      },
      {
        name: 'Cavity Detection and Treatment',
        description: 'Laser technology allows early cavity detection and conservative treatment, preserving healthy tooth structure.',
      },
      {
        name: 'Teeth Whitening',
        description: 'Laser-assisted whitening for fast, dramatic results, activating whitening agents for optimal brightness.',
      }
    ],
    benefits: [
      'Minimally invasive procedures',
      'Reduced pain and discomfort',
      'Faster recovery times',
      'Enhanced precision',
      'Less bleeding during procedures',
      'Better tissue preservation',
      'Improved sterilization'
    ],
    whyChooseUs: [
      {
        title: "Minimally Invasive",
        description: "Laser dentistry uses focused light to target specific areas with precision, reducing the need for traditional surgical methods."
      },
      {
        title: "Enhanced Precision",
        description: "Laser technology allows for the accurate removal of tissue, preserving healthy areas and promoting better outcomes.",
      },
      {
        title: "Less Pain and Discomfort",
        description: "Most laser treatments require little to no anesthesia, making them more comfortable for patients.."
      },
      {
        title: "Long-Term Success",
        description: "Our lasers are safe and effective, designed to provide the best possible results with minimal risks."
      }, 
    ],
    faqs: [
      {
        question: 'Is laser dentistry safe?',
        answer: 'Yes, laser dentistry is safe and precise, with minimal discomfort. Our FDA-approved lasers are operated by trained professionals.',
      },
      {
        question: 'Does laser treatment hurt?',
        answer: 'Laser treatments are typically pain-free and often require little to no anesthesia.',
      }
    ],
    ctaSection: {
      title: "Ready to experience the benefits of laser dentistry?",
      description: "Contact Unidental today to schedule a consultation with our expert team",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental provides advanced laser dental treatments, offering minimally invasive care with faster recovery and superior results.'
  },

'/kids-dentist': {
    title: 'Pediatric Dentistry',
    documentTitle: 'Best Pediatric Dental Services at Unidental | Kids Dentist',
    overview: 'Our pediatric dentistry services address children\'s unique dental needs, focusing on gentle, preventive care to support lifelong oral health. We create a fun, welcoming environment that helps kids develop positive associations with dental care.',
    procedures: [
      {
        name: 'Dental Cleanings for Children',
        description: 'Gentle cleanings to maintain cavity-free teeth, using child-friendly techniques to make the experience enjoyable and educational.',
      },
      {
        name: 'Fluoride Treatments',
        description: 'Strengthens enamel and protects against cavities with professional-grade fluoride, tailored for different age groups.',
      }
    ],
    benefits: [
      'Early detection of dental issues',
      'Preventative care for lifelong oral health',
      'Gentle treatments for kids',
      'Proper oral hygiene habits',
      'Positive dental experiences',
      'Prevention of dental anxiety'
    ],
    whyChooseUs: [
      {
        title: "Positive Dental Experiences",
        description: "We create a fun and relaxed environment that helps children feel at ease, preventing dental anxiety and fostering positive associations with dental care."
      },
      {
        title: "Expert Pediatric Dentists",
        description: "Our team of experienced pediatric dentists is dedicated to your child’s health and comfort, providing expert care at every stage of their growth."
      },
      {
        title: "Early Intervention:",
        description: "By starting dental care early, we can identify potential issues and provide preventive treatment to promote healthy oral development."
      },
      {
        title: "Gentle, Kid-Friendly Care",
        description: "Our team uses techniques that are specially designed for children, ensuring comfort and ease during each visit.",
      } 
    ],
    faqs: [
      {
        question: 'When should my child first see a dentist?',
        answer: 'The American Dental Association recommends a child\'s first visit by age 1 or within 6 months after the first tooth appears.',
      }
    ],
    ctaSection: {
      title: "Ready to start your child’s journey to a healthy smile?",
      description: "We're here to ensure your child’s teeth stay healthy and bright from the very start.",
      buttonText: "Book Your Consultation Today"
    },
    metaDescription: 'Unidental offers expert pediatric dentistry with gentle, preventive care that helps children develop healthy dental habits in a comfortable environment.'
  }
};