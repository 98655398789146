export const locationData = {
    hyderabad: {
      hero: {
        title: "Dental Care You Can Trust in Hyderabad",
        subtitle: "Get in today for affordable dental care with a team that's in your corner. From general dentistry to dentures and implants, we've got you.",
        contactInfo: [
          {
            icon: "MapPin",
            text: "2nd Floor, Shree Krishna Diagnostics, Beside Kalamandir, KPHB Main Rd, Kukatpally, Hyderabad, Telangana 500072, India"
          },
          {
            icon: "Phone",
            text: "+91 6305971445"
          },
          {
            icon: "Clock",
            text: "10:00am - 8:00 pm"
          }
        ]
      },
      services: {
        title: "Our Services at Unidental Hyderabad",
        items: [
          {
            title: "General Dentistry",
            description: "Cleanings, exams, cavity fillings, fluoride treatments, and more"
          },
          {
            title: "Cosmetic Dentistry",
            description: "Teeth whitening, veneers, smile makeovers"
          },
          {
            title: "Restorative Dentistry",
            description: "Crowns, bridges, root canals, and dental implants"
          },
          {
            title: "Preventive Care",
            description: "Routine check-ups, oral cancer screenings, and more"
          }
        ]
      },
      features: {
        title: "Why Choose Unidental Hyderabad?",
        items: [
          {
            icon: "CheckCircle2",
            title: "Experienced Local Dentists",
            description: "Our Hyderabad team is made up of experienced professionals dedicated to patient-centered care."
          },
          {
            icon: "MapPin",
            title: "Convenient Location",
            description: "Centrally located to make dental visits easy and accessible."
          },
          {
            icon: "Clock",
            title: "Modern Facility",
            description: "Our Hyderabad clinic is equipped with the latest dental technology for safe, effective treatments."
          },
        ]
      },
      testimonials: {
        title: "What Our Hyderabad Patients Say About Us",
        items: [
          {
            text: "I found my new go-to dental clinic in Hyderabad! The team is professional, and I felt so comfortable. Highly recommend!",
            author: "Priya S."
          },
          {
            text: "Great service and a wonderful experience from start to finish. The Unidental Hyderabad team is excellent!",
            author: "Rajiv K."
          }
        ]
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "How often should I visit Unidental Hyderabad for a check-up?",
            answer: "We recommend twice-yearly visits for routine cleanings and exams to maintain optimal oral health."
          },
          {
            question: "What payment options are available at Unidental Hyderabad?",
            answer: "We accept various payment options, including credit/debit cards, insurance, and flexible payment plans."
          }
        ]
      },
      cta: {
        title: "Ready to Schedule Your Visit?",
        text: "Take the first step towards a healthier smile with Unidental Hyderabad",
        buttonText: "Book Appointment"
      }
    },
    anantapur: {
      hero: {
        title: "Dental Care You Can Trust in Anantapur",
        subtitle: "Get in today for affordable dental care with a team that's in your corner. From general dentistry to dentures and implants, we've got you.",
        contactInfo: [
          {
            icon: "MapPin",
            text: "1-1348 Near crescent function Hall, 80 Feet Rd, opp. to Sakshi, Anantapur, Andhra Pradesh 515004, India"
          },
          {
            icon: "Phone",
            text: "+91 7075890089"
          },
          {
            icon: "Clock",
            text: "9:00am - 10:00 pm"
          }
        ]
      },
      services: {
        title: "Our Services at Unidental Anantapur",
        items: [
          {
            title: "General Dentistry",
            description: "Cleanings, exams, cavity fillings, fluoride treatments, and more"
          },
          {
            title: "Cosmetic Dentistry",
            description: "Teeth whitening, veneers, smile makeovers"
          },
          {
            title: "Restorative Dentistry",
            description: "Crowns, bridges, root canals, and dental implants"
          },
          {
            title: "Preventive Care",
            description: "Routine check-ups, oral cancer screenings, and more"
          }
        ]
      },
      features: {
        title: "Why Choose Unidental Anantapur?",
        items: [
          {
            icon: "CheckCircle2",
            title: "Experienced Local Dentists",
            description: "Our Anantapur team is made up of experienced professionals dedicated to patient-centered care."
          },
          {
            icon: "MapPin",
            title: "Convenient Location",
            description: "Centrally located to make dental visits easy and accessible."
          },
          {
            icon: "Clock",
            title: "Modern Facility",
            description: "Our Anantapur clinic is equipped with the latest dental technology for safe, effective treatments."
          },
        ]
      },
      testimonials: {
        title: "What Our Anantapur Patients Say About Us",
        items: [
          {
            text: "I found my new go-to dental clinic in Anantapur! The team is professional, and I felt so comfortable. Highly recommend!",
            author: "Srinivas R."
          },
          {
            text: "Great service and a wonderful experience from start to finish. The Unidental Anantapur team is excellent!",
            author: "Lakshmi P."
          }
        ]
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "How often should I visit Unidental Anantapur for a check-up?",
            answer: "We recommend twice-yearly visits for routine cleanings and exams to maintain optimal oral health."
          },
          {
            question: "What payment options are available at Unidental Anantapur?",
            answer: "We accept various payment options, including credit/debit cards, insurance, and flexible payment plans."
          }
        ]
      },
      cta: {
        title: "Ready to Schedule Your Visit?",
        text: "Take the first step towards a healthier smile with Unidental Anantapur",
        buttonText: "Book Appointment"
      }
    },
    goa: {
      hero: {
        title: "Dental Care You Can Trust in Goa",
        subtitle: "Get in today for affordable dental care with a team that's in your corner. From general dentistry to dentures and implants, we've got you.",
        contactInfo: [
          {
            icon: "MapPin",
            text: "Manipal Hospitals Goa, Dr E Borges Rd, Dona Paula, Durgavado, Goa 403004, India"
          },
          {
            icon: "Phone",
            text: "+91 8326632500"
          },
          {
            icon: "Clock",
            text: "9:00am - 10:00 pm"
          }
        ]
      },
      services: {
        title: "Our Services at Unidental Goa",
        items: [
          {
            title: "General Dentistry",
            description: "Cleanings, exams, cavity fillings, fluoride treatments, and more"
          },
          {
            title: "Cosmetic Dentistry",
            description: "Teeth whitening, veneers, smile makeovers"
          },
          {
            title: "Restorative Dentistry",
            description: "Crowns, bridges, root canals, and dental implants"
          },
          {
            title: "Preventive Care",
            description: "Routine check-ups, oral cancer screenings, and more"
          }
        ]
      },
      features: {
        title: "Why Choose Unidental Goa?",
        items: [
          {
            icon: "CheckCircle2",
            title: "Experienced Local Dentists",
            description: "Our Goa team is made up of experienced professionals dedicated to patient-centered care."
          },
          {
            icon: "MapPin",
            title: "Convenient Location",
            description: "Located within Manipal Hospitals Goa for easy access to comprehensive care."
          },
          {
            icon: "Clock",
            title: "Modern Facility",
            description: "Our Goa clinic is equipped with the latest dental technology for safe, effective treatments."
          },
        ]
      },
      testimonials: {
        title: "What Our Goa Patients Say About Us",
        items: [
          {
            text: "I found my new go-to dental clinic in Goa! The team is professional, and I felt so comfortable. Highly recommend!",
            author: "Maria F."
          },
          {
            text: "Great service and a wonderful experience from start to finish. The Unidental Goa team is excellent!",
            author: "John D."
          }
        ]
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "How often should I visit Unidental Goa for a check-up?",
            answer: "We recommend twice-yearly visits for routine cleanings and exams to maintain optimal oral health."
          },
          {
            question: "What payment options are available at Unidental Goa?",
            answer: "We accept various payment options, including credit/debit cards, insurance, and flexible payment plans."
          }
        ]
      },
      cta: {
        title: "Ready to Schedule Your Visit?",
        text: "Take the first step towards a healthier smile with Unidental Goa",
        buttonText: "Book Appointment"
      }
    }
  };