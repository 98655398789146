import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import ServiceDetail from './components/ServiceDetail/ServiceDetails';
import HomePage from './pages/HomePage/HomePage';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
// import Chatbot from './components/ChatBot/Chatbot';
import ChatbotComponent from './components/Chatbot_New/ChatBot';
import AppointmentForm from './components/AppointmentForm/AppointmentForm';
import LandingPage from './pages/Locations/HydLandingPage';
import './App.scss';

function App() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  return (
    <Router>
      <div className="app-container">
        <Navbar openForm={openForm} />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<HomePage openForm={openForm} />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/services/:serviceId" element={<ServiceDetail />} />
            <Route path="/locations/:location" element={<LandingPage/>} />
          </Routes>
        </main>
        <Footer />
        {/* <ChatbotComponent /> */}
        {isFormOpen && <AppointmentForm closeForm={closeForm} />}
      </div>
    </Router>
  );
}

export default App;